import React, { useRef, useLayoutEffect, useState } from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { throttle } from 'lodash'

import DocPreview from 'components/DocPreview'

const DocShow = ({ fontFamily, markup }) => {
  const containerRef = useRef(null)
  const [scale, setScale] = useState(1)
  const { pages, pageWidth, pageHeight } = markup

  const updateScale = throttle(() => {
    const width = containerRef.current.getBoundingClientRect().width
    setScale(width / pageWidth)
  }, globalThis.CONFIG_VIEW_RESIZE_DELAY)

  useLayoutEffect(() => {
    updateScale()

    window.addEventListener('resize', updateScale)

    return () => {
      window.removeEventListener('resize', updateScale)
    }
  }, [updateScale])

  return (
    <div className="DocShow" ref={containerRef}>
      {Object.entries(pages).map(([pageId, page]) => {
        const pageMarkup = {
          pageHeight,
          pageWidth,
          pageOrder: [pageId],
          pages: {
            [pageId]: page,
          },
        }

        return (
          <div
            className="DocShow__page"
            key={pageId}
            style={{
              height: pageHeight * scale,
            }}
          >
            <div
              className="DocShow__page-content"
              style={{
                transform: `scale(${scale})`,
              }}
            >
              <DocPreview
                fontFamily={fontFamily}
                markup={pageMarkup}
                zoom={scale}
                previewImage={false}
              />
            </div>
          </div>
        )
      })}
    </div>
  )
}

DocShow.propTypes = exact({
  fontFamily: PropTypes.string.isRequired,
  markup: PropTypes.object.isRequired,
})

export default DocShow
